<template>
  <div class="flex-column verify-block">
    <vs-alert :active="responseMessage" color="success" icon="new_releases">
      {{ responseMessage }}
    </vs-alert>
    <vs-alert :active="verificationError" color="danger" icon="new_releases" style="height: 40px;">
      {{ verificationErrorMessage }}
    </vs-alert>
    <div class="flex-column items-stretch">
      <vs-input
        label="Введите код подтверждения"
        v-model="verificationCode"
        class="flex-none mb-4"
        ref="code-input"
        style="width: 100%;"
      />

      <div class="flex">
        <vs-button class="send-code-btn mr-2 flex-1" type="border" @click="sendCode">
          Выслать код
        </vs-button>
      
        <vs-button class="confirm-code-btn ml-2 flex-1" color="success" @click="verifyCode">
          ПОДТВЕРДИТЬ
        </vs-button>
      </div>
      
    </div>
  </div>
</template>

<script>
import Inputmask from "inputmask";

export default {
  props: {
    customerId: {
      type: [Number, String]
    },
    customerPhone: {
      type: [Number, String]
    }
  },
  data() {
    return {
      smsCode: null,
      verificationCode: "",
      verificationError: false,
      verificationErrorMessage:
        "Произошла ошибка верификации, повторите попытку",
      responseMessage: false
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    sendCode() {
      this.responseMessage = false;

      let url;
      if (this.customerId)
        url = `/udata/users/send_user_verification_code/${this.customerId}/.json`;
      if (this.customerPhone)
        url = `/udata/users/send_phone_verification_code/${this.customerPhone}/.json`;

      this.$http({
        method: "get",
        url: url
      })
        .then(response => {
          let data = response.data;
          if (data.error) {
            this.verificationError = true;
            this.verificationErrorMessage = data.error;
          }
          if (data.result == "success") {
            this.responseMessage = data.message;
            this.smsCode = data.code ?? null;
          }
        })
        .catch(err => {
          console.log(err);
          this.verificationError = true;
          this.verificationErrorMessage =
            "Произошла ошибка при отправке кода, повторите попытку";
        });
    },
    verifyCode() {
      this.responseMessage = false;

      if (this.customerPhone && this.smsCode == this.verificationCode) {
        this.responseMessage = "Телефон успешно подтвержден";
        this.$emit("phoneVerified");
        let confirm_code_form_btn = document.querySelector('.phone_confirmation_btn');
        document.querySelector('.send-code-btn').disabled = true;
        document.querySelector('.confirm-code-btn').disabled = true;
        confirm_code_form_btn.disabled = true;
        confirm_code_form_btn.classList.remove('vs-button-danger');
        confirm_code_form_btn.classList.add('vs-button-success');
        confirm_code_form_btn.querySelector('.vs-button--text').textContent = 'Подтверждён ✓';
        document.querySelector('.vs-popup--close').click();
        return;
      }

      this.$http({
        method: "get",
        url: `/udata/users/check_user_verification_code/${this.customerId}/${this.verificationCode}/.json`
      })
        .then(response => {
          let data = response.data;
          console.log(data.code);
          if (data.code == 0) {
            console.log('error');
          }
          if (data.error) {
            this.verificationError = true;
            this.verificationErrorMessage = data.error;
          }
          if (data.result == "success") {
            this.responseMessage = data.message;
            this.$store.dispatch("getActiveClient", {
              barcode: this.$store.state.activeClient.barcode,
              mode: "info"
            });
          }
        })
        .catch(err => {
          console.log(err);
          this.verificationError = true;
          this.verificationErrorMessage =
            "Произошла ошибка верификации, повторите попытку";
        });
    }
  },
  mounted() {
    let codeInput = this.$refs["code-input"].$refs["vsinput"];
    Inputmask("9{4}").mask(codeInput);
  }
};
</script>

<style>
  .verify-block div.con-vs-alert {
    height: 40px;
  }
</style>
