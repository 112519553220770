<!-- =========================================================================================
	File Name: Registration.vue
	Description: Регистрация нового посетителя
========================================================================================== -->

<template>
  <div id="registration-wizard">
    <form-wizard ref="wizard"
      step-size="xs"
      color="rgba(var(--vs-primary), 1)" errorColor="rgba(var(--vs-danger), 1)" 
      @on-error="onError"
      @on-complete="onComplete"
      :title="null" :subtitle="null" 
      :finishButtonText="finishButtonText" nextButtonText="Далее" backButtonText="Назад">

      <div class="mb-8" 
        v-if="errorMsg">
        <span class="text-danger">{{errorMsg}} </span>
        <span class="text-warning underline cursor-pointer"
          v-if="client_check_response && client_check_response.customer_id"
          @click="selectCustomer">Выбрать найденного пользователя ID: {{client_check_response.customer_id}}
        </span>
      </div>

      <!-- tab 1 content -->
      <tab-content title="Шаг 1" class="mb-5" icon="feather icon-home" :before-change="validateStep1">
        <form data-vv-scope="step-1">
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full"><span>Имя</span></div>
            <div class="vx-col sm:w-2/3 w-full">
              <vs-input class="w-full" icon-pack="feather" icon="icon-user" icon-no-border 
                  v-model="userForm.data.new.fname" name="fname" v-validate="{ required: true, regex: /^([A-Za-zА-Яа-я -])+$/ }" />
              <span class="text-danger">{{ errors.first('step-1.fname') }}</span>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full"><span>Фамилия</span></div>
            <div class="vx-col sm:w-2/3 w-full">
              <vs-input class="w-full" icon-pack="feather" icon="icon-user" icon-no-border 
                  v-model="userForm.data.new.lname" name="lname" v-validate="{ required: true, regex: /^([A-Za-zА-Яа-я -])+$/ }" />
              <span class="text-danger">{{ errors.first('step-1.lname') }}</span>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full"><span>Отчество</span></div>
            <div class="vx-col sm:w-2/3 w-full">
              <vs-input class="w-full" icon-pack="feather" icon="icon-user" icon-no-border 
                  v-model="userForm.data.new.father_name" name="father_name" v-validate="{ regex: /^([A-Za-zА-Яа-я -])+$/ }" />
              <span class="text-danger">{{ errors.first('step-1.father_name') }}</span>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full"><span>Email</span></div>
            <div class="vx-col sm:w-2/3 w-full">
              <vs-input class="w-full" icon-pack="feather" icon="icon-mail" icon-no-border 
                  v-model="userForm.data.new['e-mail']" name="email" ref="email" v-validate="{ rules: { email_or_phone: ['phone'], email: true } }" />
              <span class="text-danger">{{ errors.first('step-1.email') }}</span>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full"><span>Телефон</span></div>
            <div class="vx-col sm:w-2/3 w-full flex">
              <div class="flex-1">
                <vs-input class="w-full" id="registration-phone" icon-pack="feather" icon="icon-smartphone" icon-no-border 
                    v-model="userForm.data.new.phone" name="phone" ref="phone" v-validate="{ rules: { email_or_phone: ['email'], phone: true } }" />
                <span class="text-danger">{{ errors.first('step-1.phone') }}</span>
              </div>
              <vs-button @click="verifyPhonePrompt = true" v-if="!userId" size="small" color="danger" type="border" class="ml-1 phone_confirmation_btn">
                Подтвердить
              </vs-button>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full"><span>Карта</span></div>
            <div class="vx-col sm:w-2/3 w-full">
              <vs-input class="w-full" id="registration-barcode" icon-pack="feather" icon="icon-credit-card" icon-no-border 
                  v-model="userForm.data.new.barcode" name="barcode" v-validate="''" />
              <span class="text-danger">{{ errors.first('step-1.barcode') }}</span>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full"><span>Группа</span></div>
            <div class="vx-col sm:w-2/3 w-full">
              <vs-select v-model="userForm.data.new.client_type" class="w-full select-large">
                <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in clientTypes" class="w-full" />
              </vs-select>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full"><span>Дата рождения</span></div>
            <div class="vx-col sm:w-2/3 w-full">
              <flat-pickr
                id="registration-birthday"
                name="birthday" v-model="userForm.data.new.birthday" 
                :config="configdateTimePicker" />
              <span class="text-danger">{{ errors.first('step-1.birthday') }}</span>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full"><span>Промокод пригласившего</span></div>
            <div class="vx-col sm:w-2/3 w-full">
              <vs-input class="w-full" icon-pack="feather" icon="icon-type" icon-no-border 
                  v-model="userForm.data.new.referer_promo"
                  name="referer_promo" 
                  v-validate="{ regex: /^([A-Za-zА-Яа-я-0-9--_ -])+$/ }" />
              <span class="text-danger">{{ errors.first('step-1.referer_promo') }}</span>
            </div>
          </div>
        </form>
      </tab-content>

      <!-- tab 2 content -->
      <tab-content title="Шаг 2" class="mb-5" icon="feather icon-briefcase" :before-change="validateStep2" >
        <form data-vv-scope="step-2">
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 pt-5 w-full"><span>Vkontakte</span></div>
            <div class="vx-col sm:w-2/3 w-full">
              <vs-input class="w-full" icon-pack="feather" icon="icon-vk" icon-no-border 
                  v-model="userForm.data.new.vkontakte" name="vkontakte" label-placeholder="vk.com/" />
              <span class="text-danger">{{ errors.first('step-1.vkontakte') }}</span>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 pt-5 w-full"><span>Instagram</span></div>
            <div class="vx-col sm:w-2/3 w-full">
              <vs-input class="w-full" icon-pack="feather" icon="icon-instagram" icon-no-border 
                  v-model="userForm.data.new.instagram" name="instagram" label-placeholder="instagram.com/" />
              <span class="text-danger">{{ errors.first('step-1.instagram') }}</span>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 pt-5 w-full"><span>Facebook</span></div>
            <div class="vx-col sm:w-2/3 w-full">
              <vs-input class="w-full" icon-pack="feather" icon="icon-facebook" icon-no-border 
                  v-model="userForm.data.new.facebook" name="facebook" label-placeholder="facebook.com/" />
              <span class="text-danger">{{ errors.first('step-1.facebook') }}</span>
            </div>
          </div>
        </form>
      </tab-content>
    </form-wizard>
    <!-- Попап подтверждения телефона -->
    <vs-popup title="Подтверждение телефона" :active.sync="verifyPhonePrompt">
      <phone-verification-popup :customerPhone="userForm.data.new.phone" @phoneVerified="setPhoneVerified" />
    </vs-popup>
  </div>
</template>

<script>
var qs = require('qs');

import Inputmask from "inputmask/dist/inputmask/inputmask.date.extensions";

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { Russian as RussianLocale } from 'flatpickr/dist/l10n/ru.js';

import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import PhoneVerificationPopup from '../components/PhoneVerificationPopup.vue';

// custom validation error message
const dict = {
  custom: {
    fname: {
      required: 'Необходимо указать имя',
      regex: "Имя может содержать только буквы, пробел и дефис"
    },
    lname: {
      required: 'Необходимо указать фамилию',
      regex: "Фамилия может содержать только буквы, пробел и дефис"
    },
    father_name: {
      required: 'Необходимо указать отчество',
      regex: "Отчество может содержать только буквы, пробел и дефис"
    },
    email: {
      email_or_phone: "Необходимо указать email или телефон",
    },
    phone: {
      email_or_phone: "Необходимо указать email или телефон",
    },
    referer_promo: {
      regex: "Промокод может содержать только буквы, цифры, дефис и символ нижнего подчеркивания",
    },
  }
};

const emptyForm =  {
  data: {
    new: {
      fname: "",
      lname: "",
      father_name: "",
      'e-mail': "",
      phone: "",
      client_type: 0,
      referer_promo: "",
    }
  }
};

export default {
    props: {
      startSession: {
        type: Boolean,
        default: true
      },
      finishButtonText: {
        type: String,
        default: "Зарегистрировать"
      }
    },
    data() {
        return {
          verifyPhonePrompt: false,
          errorMsg: null,
          client_check_response: null,

          userForm: {
            data: {
              new: {
                fname: "",
                lname: "",
                father_name: "",
                'e-mail': "",
                phone: "",
                client_type: 0,
                referer_promo: "",
              }
            }
          },

          configdateTimePicker: {
            dateFormat: 'd/m/Y',
            locale: RussianLocale,
            allowInput: true,
            disableMobile: true
          },
          clientTypes: [
            {id: 0, name: 'Нет группы'}
          ]
        }
    },
    computed: {
      userId() {
        return this.userForm.data.new.id; 
      }
    },
    methods: {
      onError: function(errorMsg){
        this.errorMsg = errorMsg
      },
      onComplete() {
        this.userForm = emptyForm;
        this.$refs.wizard.reset();
        this.$emit('complete');
      },
      selectCustomer() {
        this.$store.dispatch('startClientSession', this.client_check_response.barcode, this.$router.push('/').catch(() => {}));
      },

      validateStep1() {
        return new Promise((resolve, reject) => {
          this.$validator.validateAll('step-1')
          .then(result => {
            if (!result) { reject("Исправьте все поля"); }
          })
          .then(() => {
            this.checkPromoExists(resolve, reject);
          })
          .then(() => {
            this.checkClientExists(resolve, reject);
          })
        })
      },
      validateStep2() {
        return new Promise((resolve, reject) => {
          this.$validator.validateAll("step-2").then(result => {
            if (!result) { reject("Исправьте все поля"); }
          }).then( () => {
            this.save(resolve, reject);
          });
        })
      },
      checkClientExists(resolve, reject) {
        this.$http({
          method: 'post',
          url: '/udata/users/is_client_exists/.json',
          data: qs.stringify(this.userForm),
          withCredentials: true
        }).then((response) => {
          if (!response.data.error) {
            this.client_check_response = null;
            resolve(true);
          } else {
            this.client_check_response = response.data;
            reject(response.data.error);
          }
        }).catch(err => {
          this.$vs.notify({
            color:'danger',
            title:'Ошибка',
            text:err
          })  
        });
      },
      checkPromoExists(resolve, reject) {
        this.$http({
          method: 'post',
          url: '/udata/users/is_promo_exists/.json',
          data: qs.stringify(this.userForm),
          withCredentials: true
        }).then((response) => {
          if (!response.data.error && response.data.result == true) {
            resolve(true);
          } else {
            reject(response.data.error);
          }
        }).catch(err => {
          this.$vs.notify({
            color:'danger',
            title:'Ошибка',
            text:err
          })  
        });
      },
      setPhoneVerified() {
        this.userForm.data.new.phone_verified = true;
      },
      save(resolve, reject) {
        this.$http({
            method: 'post',
            url: '/udata/users/register_client.json',
            data: qs.stringify(this.userForm),
            withCredentials: true
          }).then((response) => {
            if (!response.data.error) {
              resolve(true);
              this.$vs.notify({
                color:'success',
                title:'Посетитель успешно зарегистрирован',
                text:'ID: ' + response.data.user_id
              })
              if (this.startSession) {
                this.$store.dispatch('startClientSession', response.data.user_barcode);
                this.$router.push('/');
              }
              this.$emit('userRegistered', response.data);
            } else {
              this.$vs.notify({
                color:'warning',
                title:'Ошибка при регистрации посетителя',
                text:response.data.error
              })
            }
          }).catch((err) => {
            this.$vs.notify({
              color:'danger',
              title:'Ошибка',
              text:err
            })                            
          })
      }
    },
    components: {
      FormWizard,
      TabContent,
      flatPickr,
      PhoneVerificationPopup
    },
    mounted() {
      var phone_input = document.getElementById("registration-phone");
      Inputmask({
        mask: "+7 (999) 999-9999", 
        autoUnmask: true
      }).mask(phone_input);
      var birthday_input = document.getElementById("registration-birthday");
      Inputmask({ 'alias': 'datetime', 'inputFormat': 'dd.mm.yyyy', "placeholder": "дд.мм.гггг" }).mask(birthday_input);

      this.$validator.localize('ru', dict);
      this.$validator.extend('phone', {
        validate: value => {
          return /\d{10}/.test(value);
        }
      });
      this.$validator.extend('email_or_phone', (value, [otherValue]) => {
        return {
          valid: value,
          data: {
            required: otherValue ? false : true,
          }
        };
      }, {
        computesRequired: true,
        hasTarget: true
      });

      this.$http({
        method: 'get',
        url: '/udata/users/get_client_types_list/.json',
        withCredentials: true
      }).then((response) => {
        if (!response.data.error) {
          this.clientTypes = this.clientTypes.concat(Object.values(response.data.items));
        } else {
          this.$vs.notify({
            color:'warning',
            title:'Ошибка',
            text:response.data.error
          })
        }
      }).catch(err => {
        this.$vs.notify({
          color:'danger',
          title:'Ошибка',
          text:err
        });
      });
    }
}
</script>

<style lang="scss">
.vs-input--input.hasValue+.vs-placeholder-label, .vs-input--input:focus+.vs-placeholder-label {
  font-size: 1.1rem;
}
</style>